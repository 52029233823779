<template>
  <div>
    <v-layout style="height: 9vh;">
      <v-toolbar dark color="primary">
        <!-- <img src="/itc-logo.png" style="width: 3em"> -->
        <v-toolbar-title class="white--text ml-1 mt-2">イージア 勤怠管理システム</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-layout>
    <v-layout>
      <v-flex xs2>
        <v-layout style="height: 91vh; background-color: #E3F2FD" column>
          <v-toolbar color="blue lighten-5" extended class="pa-2">
            <span v-if="specifiedDate === ''" class="blue-grey--text text--darken-4 subheading">{{ yesterdayDate }}</span>
            <v-icon v-if="specifiedDate === ''" color="gray" @click.native.stop="dialog = true">help_outline</v-icon>
            <v-dialog v-model="dialog" width="700">
              <v-card>
                <v-card-title class="headline">前日モード（夜勤モード）</v-card-title>
                <v-card-text>前日の「退勤」を打刻すると、前日から連続した勤務として打刻ができます。
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" flat="flat" @click.native="dialog = false">閉じる</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer />
            <template v-slot:extension>
              <v-toolbar-title v-if="specifiedDate === ''" class="mb-2 ml-3 blue-grey--text text--darken-4">
                {{ timeHM | add24Hours }}
              </v-toolbar-title>
              <v-spacer />
            </template>
          </v-toolbar>
          <v-layout v-if="page === 8" fill-height>
            <v-flex xs12 align-self-center class="text-xs-center" style="background-color: white;">
              <v-btn fab dark color="primary" @click="readQr" style="width: 10em; height: 10em">
                <v-icon dark large>lock_open</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="page === 1" row wrap>
            <v-flex xs12 style="background-color: white; margin-top: 17vh">
              <v-layout row wrap fill-height style="background-color: #E3F2FD">
                <v-flex xs12 class="text-xs-center" style="position: relative">
                  <v-btn
                    outline
                    color="blue darken-4"
                    style="width: 17vh;height: 30vh;font-size:1.7em;border:solid 3px;border-radius: 0.5em;"
                    :disabled="disabledButtons"
                    @click="clickedLeavingSpanningDate()"
                  >
                    退勤
                    <v-icon dark style="font-size: 1.6em">brightness_3</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex xs10>
        <v-layout style="height: 91vh; background-color: white" column>
          <v-toolbar v-if="isLeavingSpanningDate === 1" color="white" extended class="pa-2"></v-toolbar>
          <v-toolbar v-else color="white" extended class="pa-2">
            <span v-if="specifiedDate === ''" class="blue-grey--text text--darken-4 headline">{{ date }}</span>
            <span v-else class="blue-grey--text text--darken-4 headline">{{ convertDate }}</span>
            <v-icon large v-if="page === 3" @click="specifiedDateMenu = true">date_range</v-icon>
              <v-menu
                v-model="specifiedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-date-picker
                  v-model="specifiedDate"
                  locale="ja-jp"
                  :day-format="date => new Date(date).getDate()"
                  @input="specifiedDateMenu = false"
                ></v-date-picker>
              </v-menu>
            <v-spacer />
            <span class="blue-grey--text text--darken-4 headline">{{ staffName }}</span>
            <template v-slot:extension>
              <v-toolbar-title v-if="specifiedDate === ''" class="mb-2 blue-grey--text text--darken-4 display-3">
                {{ timeHM }}<span class="mb-2 blue-grey--text text--darken-4 title">{{ timeS }}</span>
                <span class="mb-2 text--darken-4 title" style="color: #00809e">現在</span>
              </v-toolbar-title>
              <v-btn
                v-if="specifiedDate !== ''"
                outline
                style="width: 150px; height: 50px;"
                color="#00809e"
                @click="specifiedDate = ''"
              >
                今日に戻る
              </v-btn>
              <v-spacer />
              <div v-if="page === 1">
                <v-btn
                  outline
                  style="width: 25vh;height: 8vh;border:solid 2px;border-radius: 0.5em; font-size: 1.8em"
                  color="indigo"
                  @click="clickedDetail()"
                >
                  詳細
                </v-btn>
              </div>
              <div v-if="page === 3">
                <v-btn
                  outline
                  style="width: 150px; height: 50px;"
                  color="#E57373"
                  @click="getLeaveApplication"
                >
                  休暇申請
                  <v-icon right dark>edit</v-icon>
                </v-btn>
              </div>
            </template>
          </v-toolbar>
          <v-layout v-if="page === 8" fill-height>
            <!-- <v-flex xs12 style="background-color: white; position: relative">
              <v-btn fab dark color="primary" @click="readQr" style="position: absolute; margin: auto; top: 0; bottom: 0; left: 0; right: 0; width: 200px; height: 200px">
                <v-icon dark large>lock_open</v-icon>
              </v-btn>
            </v-flex>-->
            <v-flex xs12 align-self-center class="text-xs-center" style="background-color: white;">
              <v-btn fab dark color="primary" @click="readQr" style="width: 10em; height: 10em">
                <v-icon dark large>lock_open</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="page === 2">
            <v-flex xs12 class="pa-5" style="background-color: white">
              <v-layout row wrap>
                <v-flex xs3 class="text-xs-center pl-5 pr-5 ">
                  <qrcode-stream :camera="camera" @decode="onDecode"></qrcode-stream>
                </v-flex>
                <v-flex xs9>
                  <p class="headline blue-grey--text text--darken-4">ＱＲコードをカメラにかざしてください</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        dark
                        v-on="on"
                      >
                      カメラ: {{ camera === 'front' ? 'フロント' : '自動' }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-for="(item, index) in items"
                        :key="index"
                        @click="camera = item.value"
                      >
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs12 class="text-xs-center mt-5">
                  <v-btn
                    flat
                    style="width: 200px; height: 100px; font-size: 2em"
                    @click="logout('reset')"
                  >
                    もどる
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-dialog v-model="confirmDialog" persistent max-width="500px">
            <v-card style="height: 50vh">
              <v-layout>
                <v-flex xs2 class="ml-3" style="margin-top: 6em">
                  <v-icon color="success">done_outline</v-icon>
                </v-flex>
                <v-flex xs10  class="mt-5">
                  <p style="font-size: 2em;"><span style="border-bottom: solid 3px #e9eef7;">{{ staffName + 'さん' }}</span></p>
                  <span style="font-size: 2em" :style="{color: category === 'attendance' ? '#1976D2' : 'orange'}">{{ worktimeRecordNames[category] }}</span>
                  <span style="font-size: 1.5em">の登録が完了しました。</span>
                  <div v-if="isAmRestTimeAutoInsert === 1">
                    <span style="font-size: 1.5em">(午前休入</span><span style="font-size: 1em">{{ beginAmRestTime }}と</span><br>
                    <span style="font-size: 1.5em">&nbsp;午前休戻</span><span style="font-size: 1em">{{ endAmRestTime }}を自動打刻しました。)</span>
                  </div>
                  <div v-if="isRestTimeAutoInsert === 1">
                    <span style="font-size: 1.5em">(休入</span><span style="font-size: 1em">{{ beginRestTime }}と</span><br>
                    <span style="font-size: 1.5em">&nbsp;休戻</span><span style="font-size: 1em">{{ endRestTime }}を自動打刻しました。)</span>
                  </div>
                  <div v-if="isPmRestTimeAutoInsert === 1">
                    <span style="font-size: 1.5em">(午後休入</span><span style="font-size: 1em">{{ beginPmRestTime }}と</span><br>
                    <span style="font-size: 1.5em">&nbsp;午後休戻</span><span style="font-size: 1em">{{ endPmRestTime }}を自動打刻しました。)</span>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
          <v-layout v-if="page === 1" row wrap>
            <v-flex xs12 style="background-color: white; margin-top: 17vh">
              <v-layout row wrap fill-height>
                <v-flex xs6 class="text-xs-center" style="position: relative">
                  <v-btn
                    outline
                    color="light-blue darken-2"
                    style="width: 80%; height: 30vh;font-size: 3em; border:solid 4px;border-radius: 0.5em;"
                    :disabled="disabledAttendance"
                    @click="clickedWorkCategory('attendance')"
                  >
                    出勤
                    <v-icon right dark style="font-size: 1.5em">access_time</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs6 class="text-xs-center" style="position: relative">
                  <v-btn
                    outline
                    color="orange"
                    style="width: 80%;height: 30vh;font-size: 3em; border:solid 4px;border-radius: 0.5em;"
                    :disabled="disabledButtons"
                    @click="clickedWorkCategory('leave')"
                  >
                    退勤
                    <v-icon right dark style="font-size: 1.5em">directions_run</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-if="page === 3" row wrap>
            <v-flex xs4 style="background-color: white">
              <v-alert type="error" :value="showNoAttendanceAlert">出勤が未登録</v-alert>
              <v-list>
                <template v-for="item in processedItems">
                  <v-alert :key="`alert-${item.id}`" type="error" :value="item.alert !== ''" v-html="item.alert" />
                  <v-list-tile :key="item.id">
                    <v-list-tile-content v-if="item.without_change_record_at === null">
                      {{ convAt(item.record_at) }}
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                      {{ convAt(item.without_change_record_at) }}
                    </v-list-tile-content>
                    <v-list-tile-content>
                      {{ convName(item.category) }}
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn icon ripple>
                        <v-icon color="red darken-1" @click="deleteConfirm(item)">highlight_off</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider :key="`divider-${item.id}`"></v-divider>
                </template>
              </v-list>
            </v-flex>
            <v-flex xs8 style="background-color: white">
              <v-layout row wrap fill-height>
                <v-flex xs6 class="text-xs-center pa-4" style="position: relative">
                  <v-btn
                    outline
                    color="indigo"
                    style="width: 100%; height: 100%; font-size: 2em"
                    :disabled="disabledAttendance"
                    @click="postWorktimeRecord('attendance')"
                  >
                    出勤
                    <v-icon right dark>access_time</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs6 class="text-xs-center pa-4" style="position: relative">
                  <v-btn
                    outline
                    color="indigo"
                    style="width: 100%; height: 100%; font-size: 2em"
                    :disabled="disabledButtons"
                    @click="postWorktimeRecord('leave')"
                  >
                    退勤
                    <v-icon right dark>directions_run</v-icon>
                  </v-btn>
                </v-flex>
                <!--
                <v-flex xs6 class="text-xs-center pa-3">
                  <v-btn
                    color="light-blue darken-1"
                    class="white--text"
                    large
                    :disabled="disabledButtons"
                    @click="postWorktimeRecord('outOfOffice')"
                  >外出</v-btn>
                </v-flex>
                <v-flex xs6 class="text-xs-center pa-3">
                  <v-btn
                    color="light-blue lighten-1"
                    class="white--text"
                    large
                    :disabled="disabledButtons"
                    @click="postWorktimeRecord('returnToOffice')"
                  >外戻</v-btn>
                </v-flex>
                -->
                <v-flex xs6 class="text-xs-center pa-4" style="position: relative">
                  <v-btn
                    outline
                    color="indigo"
                    style="width: 100%; height: 100%; font-size: 2em"
                    :disabled="disabledButtons"
                    @click="postWorktimeRecord('rest')"
                  >
                    休入
                    <v-icon right dark>local_cafe</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs6 class="text-xs-center pa-4" style="position: relative">
                  <v-btn
                    outline
                    color="indigo"
                    style="width: 100%; height: 100%; font-size: 2em"
                    :disabled="disabledButtons"
                    @click="postWorktimeRecord('restart')"
                  >
                    休戻
                    <v-icon right dark>reply</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 class="text-xs-center mt-3">
                  <v-btn
                  flat
                  style="font-size: 2em"
                  @click="logout()"
                  >
                    ログアウト
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
            <v-layout v-if="page === 4">
            <v-flex xs4>
              <v-layout column fill-height>
                <v-flex xs12 class="text-xs-center pt-2">
                  <v-item-group style="margin-top: 2px">
                    <v-item>
                      <v-btn flat icon class="display-1" large @click="prevYear" style="color: #616161; margin: 0 0 7px 0">
                        <v-icon>navigate_before</v-icon>
                      </v-btn>
                    </v-item>
                    <v-item>
                      <div style="display: inline-block" class="blue-grey--text text--darken-4 headline">{{ formatedCalendarStart }}</div>
                    </v-item>
                    <v-item>
                      <v-btn flat icon class="display-1" large @click="nextYear" style="color: #616161; margin: 0 0 7px 0">
                        <v-icon>navigate_next</v-icon>
                      </v-btn>
                    </v-item>
                  </v-item-group>
                </v-flex>
                <v-flex xs12 grow style="overflow-y: scroll">
                  <v-card height="100%">
                  <v-list style="">
                    <template v-for="item in leaveApplicationItems">
                      <div v-if="!(notIsPastLeaveApplicationItem(item.application_date) && item.is_approved === 0)" :key="item.id">
                        <v-list-tile :key="item.id" :id="`${item.category}-${item.application_date}`">
                          <v-list-tile-content>
                            <v-list-tile-title>{{ convAtDate(item.application_date) }}</v-list-tile-title>
                            <v-list-tile-sub-title>{{ categoryName[item.category] }}</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <div v-if="notIsPastLeaveApplicationItem(item.application_date)">
                              <div v-if="item.is_approved !== 0">
                                <v-btn round outline :disabled="true" class="approved-chip">消化済み</v-btn>
                              </div>
                            </div>
                            <div v-else>
                              <div v-if="item.is_approved === 0">
                                <v-btn
                                  outline
                                  round
                                  color="indigo"
                                  class="approved-chip"
                                  @click="deleteLeaveApplication(item)"
                                >
                                  申請中
                                  <v-icon right dark>cancel</v-icon>
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-btn round outline :disabled="true" color="success" class="approved-chip">承認済み</v-btn>
                              </div>
                            </div>
                          </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="`divider-${item.id}`"></v-divider>
                      </div>
                    </template>
                  </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs8 class="pa-3" style="border-left: 1px solid #E0E0E0">
            <h3 class="headline">休暇申請</h3>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-flex xs5>
                    <v-select
                      v-model="editedItem.category"
                      label="種別"
                      :items="categoryItems"
                      item-text="v"
                      item-value="k"
                      :rules="[v => !!v || '種別を選択してください']"
                      required
                    />
                  </v-flex>
                  <v-flex xs2></v-flex>
                  <v-flex xs5>
                    <v-menu
                      v-model="applicationDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.application_date"
                          label="申請日"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.application_date"
                        locale="ja-jp"
                        :day-format="date => new Date(date).getDate()"
                        @input="applicationDateMenu = false"
                        ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs5 @click="$vuetify.goTo('no-Thu, 05 Dec 2019 00:00:00 GMT', { duration: 1000, offset: 0, easing: 'linear' })">
                    取得済み日数: {{ ownLeaveDays - remainingLeaveDays}} 日 / {{ ownLeaveDays }} 日
                  </v-flex>
                  <v-flex xs2></v-flex>
                  <v-flex xs5 v-if="(editedItem.category === 'transfer_leave') || (editedItem.category === 'alternative_leave')">
                    <v-menu
                      v-model="basisDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.basis_date"
                          :label="editedItem.category === 'transfer_leave' ? '振替の基日' : '代替の基日'"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.basis_date"
                        locale="ja-jp"
                        :day-format="date => new Date(date).getDate()"
                        @input="basisDateMenu = false"
                        ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-toolbar flat color="white" class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  flat
                  @click="page = 3"
                >
                  cancel
                </v-btn>
                <v-chip
                  v-if="editedItem.category === 'paid_leave' && this.remainingLeaveDays === 0" label>
                  有給休暇の取得日数が上限に達しています。</v-chip>
                <v-btn
                  v-else
                  color="success"
                  flat
                  @click="postLeaveApplication"
                >
                  申請
                </v-btn>
                <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  color="success"
                  align-center
                >
                  <div class="text-xs-center" style="width: 100%">登録完了</div>
                </v-snackbar>
              </v-toolbar>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { QrcodeStream } from 'vue-qrcode-reader'
import cloneDeep from 'lodash/cloneDeep'
import SoundTools from '@/mixins/SoundTools'

/*
moment.defineLocale('ja', {
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
})
*/

let defaultEditedItem = {
  id: 0,
  staff_id: 0,
  application_date: moment().format('YYYY-MM-DD'),
  category: null,
  basis_date: moment().format('YYYY-MM-DD'),
  is_approved: 0
}

export default {
  mixins: [SoundTools],
  components: {
    QrcodeStream
  },
  data: () => ({
    remainingLeaveDays: 0,
    isAmRestTimeAutoInsert: 0,
    isPmRestTimeAutoInsert: 0,
    isRestTimeAutoInsert: 0,
    beginRestTime: '',
    endRestTime: '',
    beginAmRestTime: '',
    endAmRestTime: '',
    beginPmRestTime: '',
    endPmRestTime: '',
    confirmDialog: false,
    dialog: false,
    specifiedDate: '',
    isLeavingSpanningDate: '',
    category: '',
    ownLeaveDays: 0,
    items: [
      { title: 'フロント', value: 'front' },
      { title: '自動', value: 'auto' }
    ],
    camera: 'front',
    valid: true,
    snackbar: false,
    timeout: 2000,
    startDate: '',
    yearMenu: false,
    calendarStart: moment().format('YYYY'),
    itemsNumber: 0,
    basisDateMenu: false,
    specifiedDateMenu: false,
    applicationDateMenu: false,
    editedItem: cloneDeep(defaultEditedItem),
    categoryItems: [
      { k: 'paid_leave', v: '有給休暇' },
      { k: 'transfer_leave', v: '振替休暇' },
      { k: 'alternative_leave', v: '代替休暇' }
    ],
    leaveApplicationItems: [],
    worktimeRecordItems: [],
    page: 1,
    categoryName: {
      paid_leave: '有給休暇',
      transfer_leave: '振替休暇',
      alternative_leave: '代替休暇'
    },
    worktimeRecordNames: {
      attendance: '出勤',
      leave: '退勤',
      outOfOffice: '外出',
      returnToOffice: '外戻',
      rest: '休入',
      restart: '休戻',
      amRest: '午前休入',
      amRestart: '午前休戻',
      pmRest: '午後休入',
      pmRestart: '午後休戻'
    },
    basisDate: moment().format('YYYY-MM-DD'),
    date: '',
    yesterdayDate: '',
    timeHM: '',
    timeS: '',
    staffName: ''
  }),
  computed: {
    convertDate () {
      return moment(this.specifiedDate).utcOffset('+09:00').format('YYYY年M月D日(ddd)')
    },
    formatedCalendarStart () {
      return this.calendarStart + '年 '
    },
    processedItems () {
      let items = []
      let countCategory = {
        attendance: 0,
        leave: 0,
        outOfOffice: 0,
        returnToOffice: 0,
        rest: 0,
        restFlg: 0,
        amRestFlg: 0,
        pmRestFlg: 0,
        restart: 0,
        amRestart: 0,
        pmRestart: 0
      }
      this.worktimeRecordItems.forEach((item, idx) => {
        item.alert = ''
        if (item.category === 'returnToOffice') {
          if (idx === 0) {
            item.alert = '外出が記録されていません。'
          } else if (countCategory.outOfOffice <= countCategory.returnToOffice) {
            item.alert = '外出が記録されていません。'
          }
        } else if (item.category === 'restart') {
          if (idx === 0) {
            item.alert = '休入が記録されていません。'
          } else if (countCategory.restFlg === 0) {
            item.alert = '休入が記録されていません。'
          }
          countCategory.restFlg = 0
        } else if (item.category === 'rest') {
          if (countCategory.restFlg === 1) {
            item.alert = '休戻が記録されていません。'
          }
          countCategory.restFlg = 1
        } else if (item.category === 'amRestart') {
          if (idx === 0) {
            item.alert = '午前休入が記録されていません。'
          } else if (countCategory.amRestFlg === 0) {
            item.alert = '午前休入が記録されていません。'
          }
          countCategory.amRestFlg = 0
        } else if (item.category === 'amRest') {
          if (countCategory.amRestFlg === 1) {
            item.alert = '午前休戻が記録されていません。'
          }
          countCategory.amRestFlg = 1
        } else if (item.category === 'pmRestart') {
          if (idx === 0) {
            item.alert = '午後休入が記録されていません。'
          } else if (countCategory.pmRestFlg === 0) {
            item.alert = '午後休入が記録されていません。'
          }
          countCategory.pmRestFlg = 0
        } else if (item.category === 'pmRest') {
          if (countCategory.pmRestFlg === 1) {
            item.alert = '午後休戻が記録されていません。'
          }
          countCategory.pmRestFlg = 1
        }
        if (countCategory.outOfOffice !== countCategory.returnToOffice) {
          if (item.alert) {
            item.alert += '<br>外戻が記録されていません。'
          } else {
            item.alert = '外戻が記録されていません。'
          }
        }

        items.push(item)

        switch (item.category) {
          case ('attendance'):
            countCategory.attendance++
            break
          case ('leave'):
            countCategory.leave++
            break
          case ('outOfOffice'):
            countCategory.outOfOffice++
            break
          case ('returnToOffice'):
            countCategory.returnToOffice++
            break
          case ('rest'):
            countCategory.rest++
            countCategory.restFlg = 1
            break
          case ('restart'):
            countCategory.restart++
            countCategory.restFlg = 0
            break
          case ('amRest'):
            countCategory.amRest++
            countCategory.amRestFlg = 1
            break
          case ('amRestart'):
            countCategory.amRestart++
            countCategory.amRestFlg = 0
            break
          case ('pmRest'):
            countCategory.pmRest++
            countCategory.pmRestFlg = 1
            break
          case ('pmRestart'):
            countCategory.pmRestart++
            countCategory.pmRestFlg = 0
            break
        }
      })
      return items
    },
    disabledAttendance () {
      if (this.specifiedDate === this.basisDate) {
        if (this.worktimeRecordItems.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        if (this.worktimeRecordItems.length > 0 || this.specifiedDate !== '') {
          return true
        } else {
          return false
        }
      }
    },
    disabledButtons () {
      if (this.specifiedDate === this.basisDate) {
        if (this.worktimeRecordItems.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        if (this.worktimeRecordItems.some((item) => { return item.category === 'leave' }) ||
          this.specifiedDate !== '') {
          return true
        } else {
          return false
        }
      }
    },
    showNoAttendanceAlert () {
      if (this.worktimeRecordItems.length > 0) {
        if (!this.worktimeRecordItems.some((item) => { return item.category === 'attendance' })) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
    setInterval(() => {
      this.date = moment().utcOffset('+09:00').format('YYYY年M月D日(ddd)')
      this.yesterdayDate = moment().subtract(1, 'days').utcOffset('+09:00').format('D日(ddd)')
      this.timeHM = '08:51'
      this.timeS = moment().utcOffset('+09:00').format('ss')
    }, 1000)
  },
  watch: {
    specifiedDate () {
      this.getWorktimeRecord()
    },
    calendarStart () {
      this.getLeaveApplication()
    },
    leaveApplicationItems () {
      this.acquiredleaveApplicationNumber()
    }
  },
  filters: {
    add24Hours: function (time) {
      return Number(time.slice(0, 2)) + 24 + time.slice(2, 5)
    }
  },
  methods: {
    clickedDetail () {
      SoundTools.clickSound()
      this.page = 2
    },
    clickedLeavingSpanningDate () {
      SoundTools.leaveSound()
      setTimeout(() => {
        this.category = 'leave'
        this.isLeavingSpanningDate = 1
        this.page = 2
      }, 500)
    },
    clickedWorkCategory (category) {
      if (category === 'attendance') {
        SoundTools.attendanceSound()
      } else {
        SoundTools.leaveSound()
      }
      setTimeout(() => {
        this.category = category
        this.page = 2
      }, 500)
    },
    getOwnLeaveDays () {
      axios.get('/api/staff')
        .then((res) => {
          this.ownLeaveDays = res.data[0].leave_days
        })
        .catch(() => {
          alert('データエラー')
        })
    },
    reset () {
      this.$refs.form.reset()
    },
    validate () {
      if (this.$refs.form.validate()) {
      }
    },
    prevYear () {
      this.calendarStart = moment(this.calendarStart, 'YYYY').subtract(1, 'years').format('YYYY')
    },
    nextYear () {
      this.calendarStart = moment(this.calendarStart, 'YYYY').add(1, 'years').format('YYYY')
    },
    acquiredleaveApplicationNumber () {
      let itemsNumber = 0
      this.leaveApplicationItems.forEach((item) => {
        if (item.is_approved === 1) {
          itemsNumber++
        }
      })
      this.itemsNumber = itemsNumber
    },
    postLeaveApplication () {
      this.validate()
      if (this.editedItem.category !== null) {
        axios.post('/api/leave-application', this.editedItem)
          .then((res) => {
            this.snackbar = true
            this.getLeaveApplication()
            this.reset()
            this.editedItem = cloneDeep(defaultEditedItem)
          })
          .catch(() => {
            alert('登録エラー')
          })
      }
    },
    deleteItem (item) {
      axios.delete('/api/leave-application/' + item.id)
        .then(() => {
          this.getLeaveApplication()
        })
        .catch(() => {
          alert('削除エラー')
        })
    },
    deleteLeaveApplication (item) {
      let category = {
        paid_leave: '有給休暇',
        transfer_leave: '振替休暇',
        alternative_leave: '代替休暇'
      }
      let confirmText = this.convAtDate(item.application_date) + ' ' +
      category[item.category] + ' を削除しますか？'
      if (confirm(confirmText)) {
        this.deleteItem(item)
      }
    },
    notIsPastLeaveApplicationItem (applicationDate) {
      if (moment(applicationDate) < moment()) {
        return true
      } else {
        return false
      }
    },
    convAtDate (date) {
      return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD')
    },
    getLeaveDays () {
      let date = moment().format('YYYY-MM-DD')
      axios.get('/api/leave-days?date=' + date)
        .then((res) => {
          this.remainingLeaveDays = res.data.days
        })
        .catch(() => {
          alert('有給休暇数取得エラー')
        })
    },
    getLeaveApplication () {
      SoundTools.clickSound()
      this.page = 4
      axios.get('/api/leave-application?select_year=' + this.calendarStart)
        .then((res) => {
          this.leaveApplicationItems = res.data
        })
        .catch(() => {
          alert('データエラー')
        })
    },
    logout (x) {
      this.specifiedDate = ''
      this.category = ''
      this.isLeavingSpanningDate = 0
      if (x === 'reset') {
        SoundTools.clickSound()
        this.page = 1
      } else {
        if (x !== 'silent') {
          SoundTools.logoutSound()
        }
        axios.post('/api/logout')
          .then(() => {
            this.page = 1
            this.staffName = ''
            this.worktimeRecordItems = []
          })
          .catch(() => {
            this.$router.push('/manager')
          })
      }
    },
    onDecode (decodedString) {
      const idPassword = decodedString.split(':')
      let postData = {
        id: idPassword[0],
        password: idPassword[1]
      }
      axios.post('/api/login', postData)
        .then((res) => {
          if (this.category === '') {
            SoundTools.openSound()
            setTimeout(() => {
              this.page = 3
              this.staffName = res.data.name
              this.getLeaveDays()
              this.getWorktimeRecord()
              this.getOwnLeaveDays()
            }, 900)
          } else if (this.isLeavingSpanningDate === 1) {
            SoundTools.openSound()
            setTimeout(() => {
              axios.post('/api/user', { category: this.category, 'is_leaving_spanning_date': 1 })
                .then(() => {
                  this.isLeavingSpanningDate = 0
                  this.logout('silent')
                })
                .catch((x) => {
                  alert(x.response.data.error)
                  this.category = ''
                  this.page = 1
                })
            }, 900)
          } else {
            SoundTools.openSound()
            setTimeout(() => {
              axios.post('/api/user', { category: this.category })
                .then((x) => {
                  this.isRestTimeAutoInsert = x.data['is_automatic_rest_time_insertion']
                  this.isAmRestTimeAutoInsert = x.data['is_automatic_am_rest_time_insertion']
                  if (x.data['is_automatic_am_rest_time_insertion'] === 1) {
                    this.beginAmRestTime = x.data['begin_am_rest_time']
                    this.endAmRestTime = x.data['end_am_rest_time']
                  }
                  this.isPmRestTimeAutoInsert = x.data['is_automatic_pm_rest_time_insertion']
                  if (x.data['is_automatic_pm_rest_time_insertion'] === 1) {
                    this.beginPmRestTime = x.data['begin_pm_rest_time']
                    this.endPmRestTime = x.data['end_pm_rest_time']
                  }
                  this.beginRestTime = x.data['begin_rest_time']
                  this.endRestTime = x.data['end_rest_time']
                  this.staffName = res.data.name
                  this.confirmDialog = true
                  setTimeout(() => {
                    this.confirmDialog = false
                    this.logout('silent')
                  }, 12000)
                })
                .catch((x) => {
                  alert(x.response.data.error)
                  this.category = ''
                  this.page = 1
                })
            }, 900)
          }
        })
        .catch(() => {
          alert('認証エラー')
          this.page = 1
        })
    },
    postWorktimeRecord (category) {
      if (category === 'attendance') {
        SoundTools.attendanceSound()
      } else if (category === 'leave') {
        SoundTools.leaveSound()
      }
      axios.post('/api/user', { category: category })
        .then(() => {
          this.getWorktimeRecord()
        })
        .catch(() => {
          alert('登録エラー')
        })
    },
    convAt (datetime) {
      return moment(datetime).tz('Asia/Tokyo').format('HH:mm:ss')
    },
    convName (name) {
      return this.worktimeRecordNames[name]
    },
    readQr () {
      SoundTools.clickSound()
      this.page = 2
    },
    deleteConfirm (item) {
      SoundTools.confirmSound()
      if (confirm(this.convName(item.category) + ' ' + this.convAt(item.without_change_record_at) + ' を削除します。')) {
        axios.delete('/api/user/' + item.id)
          .then(() => {
            this.getWorktimeRecord()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    },
    getWorktimeRecord () {
      axios.get('/api/user?specified_date=' + this.specifiedDate)
        .then((res) => {
          this.worktimeRecordItems = res.data
        })
        .catch(() => {
          alert('エラー')
        })
    }
  }
}
</script>
<style scoped>
.approved-chip {
  width: 7em;
  justify-content: center;
}
</style>
