<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items2" divider=">"></v-breadcrumbs>
    <v-layout>
    </v-layout>

    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">
          作業
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="#6ac67b" dark class="mb-2" @click="newEdit">追加</v-btn>
      </v-toolbar>
      <v-data-table
       :headers="headers"
       :items="items"
       hide-actions
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" outline>
            データがありません
          </v-alert>
        </template>
        <template v-slot:items="props">
          <td class="project-td">{{ props.item.name }}</td>
          <td class="project-td justify-center layout px-0" :class="props.item.class_name">
            <v-icon
              small
              color="primary"
              class="mr-4"
              @click="editItem(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              small
              color="accent"
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">作業編集</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs4 style="margin: auto 0">
                <v-subheader class="font-weight-bold">
                  作業名
                </v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-text-field v-model="editedItem.name" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" flat @click="dialog = false">Cancel</v-btn>
          <v-btn color="success" flat @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import MyToolbar from '@/components/MyToolbar'

let defaultItem = {
  id: 0,
  name: ''
}

export default {
  components: {
    MyToolbar
  },
  data: () => {
    return {
      items2: [
        {
          text: '集計データ',
          disabled: false,
          href: '/#/manager/automatic-aggregate'
        },
        {
          text: '作業別集計データ',
          disabled: false,
          href: '/#/manager/project-aggregate'
        },
        {
          text: '作業',
          disabled: true
        }
      ],
      dialog: false,
      items: [],
      headers: [
        {
          text: '作業名',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'project-header'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'id',
          width: '100',
          class: 'project-header'
        }
      ],
      editedItem: cloneDeep(defaultItem)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.items = res.data
        })
        .catch((error) => {
          console.log(error)
          alert('Error')
        })
    },
    newEdit () {
      this.editedItem = cloneDeep(defaultItem)
      this.dialog = true
    },
    save () {
      if (this.editedItem.id) {
        axios.put('/api/manager/project/' + this.editedItem.id, this.editedItem)
          .then((res) => {
            this.fetch()
            this.dialog = false
          })
          .catch((error) => {
            console.log(error)
            alert('Error')
          })
      } else {
        axios.post('/api/manager/project', this.editedItem)
          .then((res) => {
            this.fetch()
            this.dialog = false
          })
          .catch((error) => {
            console.log(error)
            alert('Error')
          })
      }
    },
    editItem (item) {
      this.editedItem = cloneDeep(item)
      this.dialog = true
    },
    deleteItem (item) {
      if (confirm(item.name + ' を削除します。')) {
        axios.delete('/api/manager/project/' + item.id)
          .then(() => {
            this.fetch()
          })
          .catch(() => {
            alert('削除エラー')
          })
      }
    }
  }
}
</script>

<style>
.project-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
  border-left: 1px solid rgba(0,0,0,0.12);
}
.project-td {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
