<template>
  <div>
    <v-layout row wrap>
      <v-toolbar flat class="py-2">
        <v-toolbar-title
          style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em"
        >
          勤務シフト確認
        </v-toolbar-title>
      </v-toolbar>

      <v-flex xs12 class="text-xs-center">
        <v-container fluid grid-list-md>
          <v-date-picker
            v-model="date1"
            :events="arrayEvents"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            :picker-date.sync="pickerDate"
            no-title
            full-width
            event-color="green lighten-1"
          ></v-date-picker>
        </v-container>
      </v-flex>

      <v-flex xs12>
        <v-container fluid grid-list-md>
          <v-alert
            v-if="items.length > 0 && isRead === 0"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            まだ確認がされていません。<br>
            勤務シフトの内容を確認していただき、<br>
            既読ボタンを押して下さい。
          </v-alert>
          <v-alert
            v-if="items.length > 0 && isRead === 1"
            :value="true"
            type="success"
            outline
          >
            確認済み。
          </v-alert>
        </v-container>
      </v-flex>

    </v-layout>

    <v-container fluid grid-list-md>
      <v-data-iterator
        :items="items"
        hide-actions
        row
        wrap
      >
        <template v-slot:header>
          <v-toolbar
            class="mb-2"
            color="#456ab8"
            dark
            flat
          >
          <v-toolbar-title>{{ date1 | dateFormatConv }}</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:no-data>
          <v-alert
            v-if="items.length === 0"
            :value="true"
            color="error"
            icon="warning"
            outline
          >
            データがありません
          </v-alert>
        </template>

        <template v-slot:item="props">
          <v-flex
            xs12
            sm6
            md4
            lg3
          >
            <v-card class="my-3">
              <v-card-title>
                <h2>{{ props.item.begin_time }}~{{ props.item.end_time }}</h2>
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-tile>
                  <v-list-tile-content class="subheading">地区:</v-list-tile-content>
                  <v-list-tile-action class="title">{{ props.item.area }}</v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">宗派:</v-list-tile-content>
                  <v-list-tile-action class="title">
                    {{ props.item.denomination }}
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">会館名:</v-list-tile-content>
                  <v-list-tile-action class="title">
                    {{ props.item.hall_name }}
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">詳細:</v-list-tile-content>
                  <v-list-tile-action class="title">
                    {{ props.item.hall_name_detail }}
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">種別:</v-list-tile-content>
                  <v-list-tile-action class="title">{{ props.item.type }}</v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">作業:</v-list-tile-content>
                  <v-list-tile-action class="title">
                    {{ props.item.project_name }}
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="subheading">備考:</v-list-tile-content>
                  <v-list-tile-action class="title">{{ props.item.memo }}</v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
        </template>
      </v-data-iterator>
    </v-container>

    <v-btn color="grey lighten-1" @click="$router.push('worktime-record')">戻る</v-btn>
    <v-btn
      v-if="items.length > 0 && isRead === 0"
      color="#6ac67b"
      dark
      @click="makeAsRead"
    >
      既読にする
    </v-btn>

  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import itemTools from '@/mixins/item_tools.js'

export default {
  mixins: [itemTools],
  data: () => ({
    arrayEvents: [],
    date1: moment().format('YYYY-MM-DD'),
    pickerDate: null,
    worktimeItems: [],
    items: [],
    isRead: 0
  }),
  mounted () {
    this.getWorkTimeShift()
  },
  watch: {
    pickerDate () {
      this.getWorkTimeShift()
    },
    date1 () {
      this.getSelectionDateWorkTimeShift()
    },
    items () {
      this.isRead = 0
      this.items.forEach((item) => {
        if (item.is_read === 1) {
          this.isRead = 1
        }
      })
    }
  },
  filters: {
    dateFormatConv: function (val) {
      return moment(val).format('YYYY/MM/DD(ddd)')
    }
  },
  methods: {
    makeAsRead () {
      let today = moment(this.date1).format('YYYY/MM/DD(ddd)')
      let worktimeIdList = []

      this.items.forEach((item) => {
        worktimeIdList.push(item.id)
      })

      if (confirm(today + 'の勤務シフト内容を既読にします。\nよろしいですか？')) {
        axios.put('/api/worktime/read/', { 'worktime_id_list': worktimeIdList })
          .then((res) => {
            this.getWorkTimeShift()
          })
          .catch(() => {
            alert('更新エラー')
          })
      }
    },
    getWorkTimeShift () {
      axios.get('/api/worktime?ym=' + this.pickerDate)
        .then((res) => {
          this.worktimeItems = res.data
          this.arrayEvents = []

          res.data.forEach((item) => {
            this.arrayEvents.push(moment(item.worktime_date).format('YYYY-MM-DD'))
          })

          this.getSelectionDateWorkTimeShift()
        })
        .catch((error) => {
          this.errorDecision(error, '/', 'データエラー[getWorkTimeShift]')
        })
    },
    getSelectionDateWorkTimeShift () {
      this.items = []
      this.worktimeItems.forEach((item) => {
        if (moment(item.worktime_date).format('YYYY-MM-DD') === this.date1) {
          this.items.push(item)
        }
      })
    }
  }
}
</script>
