import Vue from 'vue'
import Router from 'vue-router'
// import Mobile from './views/Mobile'
import Login from './views/Login'
import ReadQr from './views/ReadQr'
import ReadQr1 from './views/ReadQr1'
import ReadQr2 from './views/ReadQr2'
import Aggregate from './views/Aggregate'
import AggregateSoul from './views/AggregateSoul'
import AggregateClosed from './views/AggregateClosed'
import AutomaticAggregate from './views/AutomaticAggregate'
import ClosedMonthlyData from './views/ClosedMonthlyData'
import Default from './views/Default'
import Holiday from './views/Holiday'
import WorktimeRecord from './views/WorktimeRecord'
import Staff from './views/Staff'
import Group from './views/Group'
import Project from './views/Project'
import ProjectAggregate from './views/ProjectAggregate'
import Worktime from './views/Worktime'
import DailyWorktime from './views/DailyWorktime'
import LeaveApplication from './views/LeaveApplication'
import LeaveApplicationManagement from './views/LeaveApplicationManagement'
import HolidayWorkApplication from './views/HolidayWorkApplication'
import WorkAbleShift from './views/WorkableShift'
import GrantLeave from './views/GrantLeave'
import OvertimeWork from './views/OvertimeWork'
import MobileLogin from './views/mobile/Login'
import MobileWorktime from './views/mobile/Worktime'
import MobileWorktimeRecord from './views/mobile/WorktimeRecord'
import MobileLeaveApplication from './views/mobile/LeaveApplication'
import MobileShiftApplication from './views/mobile/ShiftApplication'
import MobileShiftConfirm from './views/mobile/ShiftConfirm'
import MobileRequestOvertimeWork from './views/mobile/RequestOvertimeWork'
import store from '@/store'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'mobile-login',
      component: MobileLogin
    },
    {
      path: '/worktime-record',
      name: 'mobile-worktime-record',
      component: MobileWorktimeRecord
    },
    {
      path: '/leave-application',
      name: 'leave-application',
      component: MobileLeaveApplication
    },
    {
      path: '/shift-application',
      name: 'shift-application',
      component: MobileShiftApplication
    },
    // 大洋工芸様用
    {
      path: '/request-overtime-work',
      name: 'request-overtime-work',
      component: MobileRequestOvertimeWork
    },
    {
      // ソール様用
      path: '/shift-confirm',
      name: 'shift-confirm',
      component: MobileShiftConfirm
    },
    {
      // 一般用
      path: '/worktime',
      name: 'worktime',
      component: MobileWorktime
    },
    {
      path: '/field',
      name: 'read-qr',
      component: ReadQr
    },
    {
      path: '/field_1',
      name: 'read-qr1',
      component: ReadQr1
    },
    {
      path: '/field_2',
      name: 'read-qr2',
      component: ReadQr2
    },
    {
      path: '/manager',
      name: 'manager-login',
      component: Login
    },
    {
      path: '/manager/aggregate',
      name: 'manager-aggregate',
      component: Aggregate
    },
    {
      path: '/manager/aggregate/soul',
      name: 'manager-aggregate-soul',
      component: AggregateSoul
    },
    {
      path: '/manager/aggregate/closed',
      name: 'manager-aggregate/closed',
      component: AggregateClosed
    },
    {
      path: '/manager/automatic-aggregate',
      name: 'manager-automatic-aggregate',
      component: AutomaticAggregate
    },
    {
      path: '/manager/closed-monthly-data',
      name: 'manager-closed-monthly-data',
      component: ClosedMonthlyData
    },
    {
      path: '/manager/worktime-record/:specifiedDate',
      name: 'manager-worktime-record-specified-date',
      component: WorktimeRecord
    },
    {
      path: '/manager/worktime-record',
      name: 'manager-worktime-record',
      component: WorktimeRecord
    },
    {
      path: '/manager/staff',
      name: 'manager-staff',
      component: Staff
    },
    {
      path: '/manager/group',
      name: 'manager-group',
      component: Group
    },
    {
      path: '/manager/worktime',
      name: 'manager-worktime',
      component: Worktime
    },
    {
      path: '/manager/daily-worktime',
      name: 'manager-daily-worktime',
      component: DailyWorktime
    },
    {
      path: '/manager/leave-application',
      name: 'manager-leave-application',
      component: LeaveApplication
    },
    {
      path: '/manager/leave-application-management',
      name: 'manager-leave-application-management',
      component: LeaveApplicationManagement
    },
    {
      path: '/manager/holiday-work-application',
      name: 'manager-holiday-work-application',
      component: HolidayWorkApplication
    },
    {
      path: '/manager/grant-leave',
      name: 'manager-grant-leave',
      component: GrantLeave
    },
    {
      path: '/manager/holiday',
      name: 'manager-holiday',
      component: Holiday
    },
    {
      path: '/manager/default',
      name: 'manager-default',
      component: Default
    },
    {
      path: '/manager/project',
      name: 'manager-project',
      component: Project
    },
    {
      path: '/manager/project-aggregate',
      name: 'manager-project-aggregate',
      component: ProjectAggregate
    },
    {
      path: '/manager/workable-shift',
      name: 'manager-workable-shift',
      component: WorkAbleShift
    },
    {
      path: '/manager/overtime-work',
      name: 'manager-overtime-work',
      component: OvertimeWork
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('staffName')) {
    store.commit('setStaffName', localStorage.getItem('staffName'))
  }
  next()
})

export default router
