export default {
  init () {
    document.getElementById('open-sound').play().catch(() => {})
    document.getElementById('open-sound').pause()
    document.getElementById('click-sound').play().catch(() => {})
    document.getElementById('click-sound').pause()
    document.getElementById('leave-sound').play().catch(() => {})
    document.getElementById('leave-sound').pause()
    document.getElementById('confirm-sound').play().catch(() => {})
    document.getElementById('confirm-sound').pause()
    document.getElementById('logout-sound').play().catch(() => {})
    document.getElementById('logout-sound').pause()
  },
  openSound () {
    document.getElementById('open-sound').play()
  },
  clickSound () {
    document.getElementById('click-sound').play()
  },
  attendanceSound () {
    document.getElementById('attendance-sound').play()
  },
  leaveSound () {
    document.getElementById('leave-sound').play()
  },
  confirmSound () {
    document.getElementById('confirm-sound').play()
  },
  logoutSound () {
    document.getElementById('logout-sound').play()
  }
}
